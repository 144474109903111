import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import styled from 'styled-components';

const DashboardContainer = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const CardTitle = styled.h2`
  color: #0f518b;
  border-bottom: 2px solid #0f518b;
  padding-bottom: 10px;
  margin-top: 0;
`;

const SectionTitle = styled.h3`
  color: #0f518b;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const UpdateList = styled.ul`
  padding-left: 20px;
  li {
    margin-bottom: 10px;
  }
`;

const MetricCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const MetricTitle = styled.span`
  font-weight: bold;
`;

const MetricValue = styled.span`
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: ${props => props.color};
`;

const TeamStats = styled.div`
  background-color: #f0f8ff;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
`;

const PerformanceChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

const PerformanceChart = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ChartAvatar = styled.span`
  font-size: 24px;
  margin-right: 10px;
`;

const ChartTitle = styled.h4`
  margin: 0;
  color: #0f518b;
`;

const ActionTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  th, td {
    border: 1px solid #e0e0e0;
    padding: 12px;
    text-align: left;
  }
  th {
    background-color: #f0f8ff;
    font-weight: bold;
    color: #0f518b;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Button = styled.button`
  padding: 8px 12px;
  background-color: #0f518b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0a3d6a;
  }
`;

const AddActionButton = styled(Button)`
  margin-top: 15px;
`;

const IndividualPerformance = styled.details`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  summary {
    font-weight: bold;
    cursor: pointer;
    color: #0f518b;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MetricsGroup = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px 20px;
  margin-bottom: 20px;
`;

const MetricsGroupTitle = styled.h3`
  color: white;
  background-color: black;
  padding: 10px;
  margin: 0 -20px 15px;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const MetricsGroupContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

const ChartWrapper = styled.div`
  grid-column: 1 / -1;
`;

const UpdateColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const UpdateColumn = styled.div`
  h4 {
    color: #0f518b;
    margin-bottom: 10px;
  }
`;

const IndividualPerformanceTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 15px;
`;

const TableHeader = styled.th`
  background-color: #f0f8ff;
  font-weight: bold;
  color: #0f518b;
  padding: 10px;
  text-align: left;
  border: 1px solid #e0e0e0;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #e0e0e0;
`;

const SmallPrint = styled.small`
  display: block;
  color: #666;
  font-size: 0.8em;
`;

const Dashboard = () => {
  const [actions, setActions] = useState([
    { description: "Cross skill team A to reduce WIP in queue B", dateAssigned: "9 Aug", teamMember: "Noura", relatedMetric: "🍍" },
    { description: "Follow up on the feature to auto-tag high-priority cases", dateAssigned: "6 Aug", teamMember: "JohnH", relatedMetric: "🍎" },
    { description: "Train the team on priority tagging", dateAssigned: "2 Jul", teamMember: "Sarah", relatedMetric: "🍎" },
    { description: "Implement new customer feedback system", dateAssigned: "12 Aug", teamMember: "Alex", relatedMetric: "🥝" },
    { description: "Review and optimize refund processing workflow onboarding training", dateAssigned: "15 Aug", teamMember: "Emma", relatedMetric: "🍊" },
  ]);

  const individualPerformanceData = [
    {
      name: "Noura",
      productivityYesterday: 85,
      productivityWeek: 92,
      throughput: 7.5,
      quality: 95,
    },
    {
      name: "JohnH",
      productivityYesterday: 78,
      productivityWeek: 88,
      throughput: 8.2,
      quality: 97,
    },
    {
      name: "Sarah",
      productivityYesterday: 92,
      productivityWeek: 90,
      throughput: 6.8,
      quality: 93,
    },
    {
      name: "Alex",
      productivityYesterday: 91,
      productivityWeek: 60,
      throughput: 6.0,
      quality: 98,
    },
    {
      name: "Emma",
      productivityYesterday: 81,
      productivityWeek: 60,
      throughput: 6.5,
      quality: 92,
    },
    {
      name: "JohnB",
      productivityYesterday: 91,
      productivityWeek: 70,
      throughput: 6.1,
      quality: 82,
    },
  ];

  const addAction = () => {
    setActions([...actions, { description: "", dateAssigned: "", teamMember: "", relatedMetric: "" }]);
  };

  const getColorForMetric = (value, threshold) => {
    if (value >= threshold.high) return "#4CAF50";
    if (value >= threshold.low) return "#FFC107";
    return "#F44336";
  };


  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text 
          x={0} 
          y={0} 
          dy={16} 
          textAnchor="end" 
          fill="#666" 
          transform="rotate(-90)"
          style={{ fontSize: '10px' }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const MetricCardComponent = ({ title, value, thresholds }) => (
    <MetricCard>
      <MetricTitle>{title}</MetricTitle>
      <MetricValue color={getColorForMetric(parseFloat(value), thresholds)}>{value}</MetricValue>
    </MetricCard>
  );


  const PerformanceChartComponent = ({ title, avatar, data, thresholds, yAxisRange }) => (
    <PerformanceChart>
      <ChartHeader>
        <ChartAvatar>{avatar}</ChartAvatar>
        <ChartTitle>{title}</ChartTitle>
      </ChartHeader>
      <ResponsiveContainer width="100%" height={160}>
        <BarChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 25 }}>
          <XAxis 
            dataKey="date" 
            height={60}
            tick={<CustomizedAxisTick />}
          />
          <YAxis 
            domain={yAxisRange || ['auto', 'auto']}
            hide={!yAxisRange}
          />
          <Tooltip />
          <Bar dataKey="value">
            {data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={getColorForMetric(entry.value, thresholds)} 
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </PerformanceChart>
  );

  return (
    <DashboardContainer>
      <Card>
        <CardTitle>Team Updates</CardTitle>
        <UpdateColumns>
          <UpdateColumn>
            <h4>Successes</h4>
            <UpdateList>
              <li>Well done James, coaching new team members</li>
              <li>Congrats John for going above & beyond in xx case</li>
            </UpdateList>
          </UpdateColumn>
          <UpdateColumn>
            <h4>Comms</h4>
            <UpdateList>
              <li>Don't forget to book annual leave</li>
              <li>Culture survey coming in two weeks</li>
            </UpdateList>
          </UpdateColumn>
        </UpdateColumns>
      </Card>

      <Card>
        <CardTitle>Performance</CardTitle>
        <div>
          <SectionTitle>Our Team</SectionTitle>
          <TeamStats>
            <p><strong>Case Volume (yesterday):</strong> 109</p>
            <p><strong>FTE (yesterday):</strong> 12</p>
          </TeamStats>
          <PerformanceChartsGrid>

          <MetricsGroup>
            <MetricsGroupTitle>🍍 Service Delivery</MetricsGroupTitle>
            <MetricsGroupContent>
              <MetricCardComponent title="GOS" value="87%" thresholds={{ high: 100, low: 100 }} />
              <MetricCardComponent title="Customer Service Level" value="63%" thresholds={{ high: 80, low: 70 }} />
              <ChartWrapper>
                <PerformanceChartComponent
                  title="Customer Service Level"
                  //avatar="🍍"
                  data={[
                    { date: "Aug 5", value: 81 },
                    { date: "Aug 6", value: 73 },
                    { date: "Aug 7", value: 77 },
                    { date: "Yesterday", value: 75 },
                  ]}
                  thresholds={{ high: 80, low: 70 }}
                  yAxisRange={[70, 85]}
                />
              </ChartWrapper>
            </MetricsGroupContent>
          </MetricsGroup>

          <MetricsGroup>
            <MetricsGroupTitle>🍊 Productivity & Cost​</MetricsGroupTitle>
            <MetricsGroupContent>
            <MetricCardComponent title="Throughput" value="8.2" thresholds={{ high: 8, low: 7 }} />
            <ChartWrapper>
            <PerformanceChartComponent
                  title="Team Throughput"
                  // avatar="🍊"
                  data={[
                    { date: "Aug 5", value: 6.5 },
                    { date: "Aug 6", value: 10.1 },
                    { date: "Aug 7", value: 8.2 },
                    { date: "Yesterday", value: 9 },
                  ]}
                  thresholds={{ high: 7, low: 7}}
                  yAxisRange={[0, 12]}
                />
              </ChartWrapper>
            </MetricsGroupContent>
          </MetricsGroup>

          <MetricsGroup>
            <MetricsGroupTitle>🍇 Efficiency & Speed​​</MetricsGroupTitle>
            <MetricsGroupContent>
            <MetricCardComponent title="Turn Around Time" value="5.2 days" thresholds={{ high: 10, low: 10 }} />
            {/* <MetricCardComponent title="Rework" value="38%" thresholds={{ high: 40, low: 30 }} /> */}
            <MetricCardComponent title="Rework" value="38%" thresholds={{ high: 30, low: 30 }} />
            <ChartWrapper>
            <PerformanceChartComponent
              title="Turn around time"
              // avatar="🍇"
              data={[
                { date: "Aug 5", value: 4.2 },
                { date: "Aug 6", value: 4.5 },
                { date: "Aug 7", value: 4.8 },
                { date: "Yesterday", value: 5.2 },
              ]}
              // thresholds={{ high: 4, low: 5 }}
              thresholds={{ high: 10, low: 10 }}
              yAxisRange={[3, 6]}
            />
              </ChartWrapper>
            </MetricsGroupContent>
          </MetricsGroup>

          <MetricsGroup>
            <MetricsGroupTitle>🍎 Quality & Compliance​​​</MetricsGroupTitle>
            <MetricsGroupContent>
            <MetricCardComponent title="Compliance rate" value="88%" thresholds={{ high: 95, low: 80 }} />
            <MetricCardComponent title="QA Pass Rate" value="73%" thresholds={{ high: 85, low: 80 }} />
            <ChartWrapper>
            <PerformanceChartComponent
              title="BCoP xx compliance (weekly)"
              // avatar="🍎"
              data={[
                { date: "W-1 Jul", value: 96 },
                { date: "W-8 Jul", value: 96.5 },
                { date: "W-15 Jul", value: 97.3 },
                { date: "W-22 Jul", value: 97 },
                { date: "Last week", value: 98 },
              ]}
              thresholds={{ high: 99, low: 97 }}
              yAxisRange={[95, 100]}
            />
              </ChartWrapper>
            </MetricsGroupContent>
          </MetricsGroup>

          <MetricsGroup>
            <MetricsGroupTitle>🥝 Customer Experience​​</MetricsGroupTitle>
            <MetricsGroupContent>
            <MetricCardComponent title="NPS" value="8%" thresholds={{ high: 50, low: 30 }} />
            {/* <MetricCardComponent title="Customer Complaints" value="17" thresholds={{ high: 20, low: 15 }} /> */}
            <MetricCardComponent title="Customer complaints" value="17" thresholds={{ high: 10, low: 10 }} />
            <ChartWrapper>
            <PerformanceChartComponent
              title="Customer complaints"
              // avatar="🥝"
              data={[
                { date: "W-1 Jul", value: 2 },
                { date: "W-8 Jul", value: 1 },
                { date: "W-15 Jul", value: 3 },
                { date: "W-22 Jul", value: 2 },
                { date: "Last week", value: 1 },
              ]}
              // until we implement min/max thresholds, we need to fake this with 0 so it looks green
              //thresholds={{ high: 5, low: 3 }}
              thresholds={{ high: 0, low: 0 }}
              yAxisRange={[0, 5]}
            />
              </ChartWrapper>
            </MetricsGroupContent>
          </MetricsGroup>

          </PerformanceChartsGrid>
        </div>
      </Card>

      <Card>
        <CardTitle>Actions</CardTitle>
        <ActionTable>
          <thead>
            <tr>
              <th>Description</th>
              <th>Date Assigned</th>
              <th>Team Member</th>
              <th>Related Metric</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {actions.map((action, index) => (
              <tr key={index}>
                <td>{action.description}</td>
                <td>{action.dateAssigned}</td>
                <td>{action.teamMember}</td>
                <td>{action.relatedMetric}</td>
                <td>
                  <Button onClick={() => console.log('Modify action', index)}>Modify</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </ActionTable>
        <AddActionButton onClick={addAction}>Add New Action</AddActionButton>
      </Card>

      <IndividualPerformance>
      <summary>Individual Performance</summary>
        <IndividualPerformanceTable>
          <thead>
            <tr>
              <TableHeader>Team Member</TableHeader>
              <TableHeader>
                Productivity
                <SmallPrint>yesterday</SmallPrint>
              </TableHeader>
              <TableHeader>
                Productivity
                <SmallPrint>week to date</SmallPrint>
              </TableHeader>
              <TableHeader>
                Throughput
                <SmallPrint>Avg Apps / 8 hours</SmallPrint>
              </TableHeader>
              <TableHeader>
                Quality
                <SmallPrint>month to date</SmallPrint>
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {individualPerformanceData.map((member, index) => (
              <tr key={index}>
                <TableCell>{member.name}</TableCell>
                <TableCell>
                  <MetricValue color={getColorForMetric(member.productivityYesterday, { high: 90, low: 80 })}>
                    {member.productivityYesterday}%
                  </MetricValue>
                </TableCell>
                <TableCell>
                  <MetricValue color={getColorForMetric(member.productivityWeek, { high: 90, low: 85 })}>
                    {member.productivityWeek}%
                  </MetricValue>
                </TableCell>
                <TableCell>
                  <MetricValue color={getColorForMetric(member.throughput, { high: 8, low: 7 })}>
                    {member.throughput}
                  </MetricValue>
                </TableCell>
                <TableCell>
                  <MetricValue color={getColorForMetric(member.quality, { high: 95, low: 90 })}>
                    {member.quality}%
                  </MetricValue>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </IndividualPerformanceTable>
      </IndividualPerformance>
    </DashboardContainer>
  );
};

export default Dashboard;
